var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('b-row',[_c('div',{staticClass:"border-3 border-right-info p-0",staticStyle:{"width":"14%"}},[_c('div',{staticClass:"navContainer"},[_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'management-settings-by-companies' },"exact-active-class":"sub-tab-nav","link-classes":[
            this.$route.name == 'management-settings-by-companies'
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("BY COMPANIES")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'management-settings-all-companies' },"exact-active-class":"sub-tab-nav","link-classes":[
            this.$route.name == 'management-settings-all-companies'
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("ALL COMPANIES")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'module-access-config-settings' },"exact-active-class":"sub-tab-nav","link-classes":[
            this.$route.name == 'module-access-config-settings'
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("SEARCH ACCESS")])]),_c('b-nav-item',{staticClass:"text-left",attrs:{"to":{ name: 'management-settings-other' },"exact-active-class":"sub-tab-nav","link-classes":[
            this.$route.name == 'management-settings-other'
              ? 'link-active'
              : 'link-inactive',
            'sub-tab-nav',
            'px-3',
            'text-truncate',
            _vm.bgTabsNavs ]}},[_c('span',{staticClass:"menu-title"},[_vm._v("PENALTIES")])])],1)]),_c('div',{staticClass:"col"},[_c('b-card',{attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name,ref:"routerView"})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }