<template>
    <div class="p-2">
      <b-row>
        <div class="border-3 border-right-info p-0" style="width: 14%">
          <div class="navContainer">
            <b-nav-item
              :to="{ name: 'management-settings-by-companies' }"
              exact-active-class="sub-tab-nav"
              :link-classes="[
                this.$route.name == 'management-settings-by-companies'
                  ? 'link-active'
                  : 'link-inactive',
                'sub-tab-nav',
                'px-3',
                'text-truncate',
                bgTabsNavs,
              ]"
              class="text-left"
            >
              <span class="menu-title">BY COMPANIES</span>
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'management-settings-all-companies' }"
              exact-active-class="sub-tab-nav"
              :link-classes="[
                this.$route.name == 'management-settings-all-companies'
                  ? 'link-active'
                  : 'link-inactive',
                'sub-tab-nav',
                'px-3',
                'text-truncate',
                bgTabsNavs,
              ]"
              class="text-left"
            >
              <span class="menu-title">ALL COMPANIES</span>
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'module-access-config-settings' }"
              exact-active-class="sub-tab-nav"
              :link-classes="[
                this.$route.name == 'module-access-config-settings'
                  ? 'link-active'
                  : 'link-inactive',
                'sub-tab-nav',
                'px-3',
                'text-truncate',
                bgTabsNavs,
              ]"
              class="text-left"
            >
              <span class="menu-title">SEARCH ACCESS</span>
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'management-settings-other' }"
              exact-active-class="sub-tab-nav"
              :link-classes="[
                this.$route.name == 'management-settings-other'
                  ? 'link-active'
                  : 'link-inactive',
                'sub-tab-nav',
                'px-3',
                'text-truncate',
                bgTabsNavs,
              ]"
              class="text-left"
            >
              <span class="menu-title">PENALTIES</span>
            </b-nav-item>
          </div>
        </div>
        <div class="col">
          <b-card no-body>
            <router-view ref="routerView" :key="$route.name" />
          </b-card>
        </div>
      </b-row>
    </div>
  </template>
    <script>
  export default {
  };
  </script>

<style lang="scss" scoped>
  
.navContainer{
  list-style:none;

}

.link-inactive:hover {
  transition: 0.25s ease;
  & a {
  margin-left: 4px; 
  }
  background: #FFFFFF !important;
  color: #726D84; 
}

.link-inactive{
    padding: 10px 15px !important; 
    background: #FFFFFF;
    color: #726D84; 
    border-radius: 6px 0px 0px 6px; 
  }



.link-active{
  padding: 10px 15px !important;
  background: #8F5FE8;
  color: #F8F6FE;
  border-radius: 6px 0px 0px 6px; 
}


.dark-layout{
  & .link-inactive{
    padding: 10px 15px !important;
    background: #17171A;
    color: #A2A2A3; 
    border-radius: 6px 0px 0px 6px; 
  }
  & .link-inactive:hover {
    transition: 0.25s ease;
    margin-left: 4px;
    background: #17171A !important;
    color: #A2A2A3; 
  }
  .link-active{
    padding: 10px 15px !important;
    background: #8F5FE8;
    color: #F8F6FE;
    border-radius: 6px 0px 0px 6px;  
  }
}

.link-active:hover{
  zoom: 1.03;
}

</style>